import React from 'react'
import './pageBannerImgBackground.scss'

 const PageBannerImgBackground = ({imgPath, title, description}) => {

  return (
        <div className='page-img-banner-wrapper' style={{ background: `url(${imgPath})`, backgroundPositionY: '70%', height: '100vh'}}>
        {/* <header className="til-cover" role="banner"> */}

          <div className='banner-text-section-wrapper'>
            <h1>{title}</h1>
            <p className='description'>{description}</p>
          </div>
          
        {/* </header> */} 
        </div>
    )
}


export default PageBannerImgBackground
