import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import IndividualHubPageLayoutTemplate from '../components/IndividualHubPageLayoutTemplate'

// import remark from 'remark'
// import remarkHTML from 'remark-html'


export const CapabilitiesTemplate = ({
  capabilitiesList,
  spotlightActivity,
  pageBannerTitle,
  pageBannerDescription,
  pageBannerImage
}) => {



  //   const PageContent = contentComponent || Content

  return (
    <>
      <IndividualHubPageLayoutTemplate
        pageBannerTitle={pageBannerTitle}
        pageBannerDescription={pageBannerDescription}
        gridList={capabilitiesList}
        spotlightActivity={spotlightActivity}
        pageBannerImage={pageBannerImage}

      />
    </>
  )
}

CapabilitiesTemplate.propTypes = {
  // bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pageBannerTitle: PropTypes.string,
  pageBannerDescription: PropTypes.string,
  capabilitiesList: PropTypes.array,
  spotlightActivity: PropTypes.array,
  pageBannerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const CapabilitiesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout
      title={frontmatter.pageBannerTitle}
      description={frontmatter.pageBannerDescription}
    >
      <CapabilitiesTemplate
        capabilitiesList={frontmatter.capabilitiesList}
        spotlightActivity={frontmatter.spotlightActivity}
        pageBannerTitle={frontmatter.pageBannerTitle}
        pageBannerDescription={frontmatter.pageBannerDescription}
        pageBannerImage={frontmatter.pageBannerImage}
      />
    </Layout>
  )
}


CapabilitiesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default CapabilitiesPage


export const pageQuery = graphql`
  query CapabilitiesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "capabilities-hub" } }) {
      frontmatter {
        pageBannerTitle
        pageBannerDescription
        pageBannerImage{
              extension
              publicURL
            }
        capabilitiesList {
          title
          link
          thumbnail {
              extension
              publicURL
            }
         }
         spotlightActivity {
          title
          link
          description
          thumbnail {
              extension
              publicURL
            }
          }
        }
        
    }
  }
`
