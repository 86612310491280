import React from 'react'
import PageBannerImgBackground from '../PageBannerImgBackground/pageBannerImgBackground'
import SpotlightActivity from '../SpotlightActivity/spotlightActivity'
import './individualHubPageLayoutTemplate.scss'

const IndividualHubPageLayoutTemplate = ({
    gridList,
    spotlightActivity,
    pageBannerTitle,
    pageBannerDescription,
    pageBannerImage
}) => {


    const getImagePath = (image) => {
        if (image?.publicURL) return image.publicURL
        else return image
      }

      
    return (
        <div className='grid-layout-wrapper'>
                <PageBannerImgBackground
                imgPath={getImagePath(pageBannerImage)}
                title={pageBannerTitle}
                description={pageBannerDescription}
                />

                <div className='content-wrapper'>
                    {gridList && gridList.map((item) => {
                        return (  
                        <> 
                            <a href={item.link}>
                                <div className='industry-thunbnail' style={{ backgroundImage: `url("${getImagePath(item.thumbnail)}")`  }}>
                                    <div className='text'>{item.title}</div>
                                    <div className='overlay'></div>
                                </div> 
                            </a>
                        </> 
                        );
                    })}
                </div> 

                
        </div> 
    )
}

export default IndividualHubPageLayoutTemplate
