import React from 'react'
import './spotlightActivity.scss'

const spotlightActivityData = [
    {
    title: 'Artificial Intelligence & Art',
    description: 'Artificial Intelligence’s capabilities are endless, according to the recently released Final Report of the National Security Commission on Artificial Intelligence...',
    link: '/trends/ai-art',
    thumbnail: '/img/ai-and-art-thumb.png'
    },
    {
    title: 'Blockchain & Cryptocurrency',
    description: 'Dogecoin, Ethereum, and numerous other cryptocurrencies have risen to the heights many investors thought to be impossible during the pandemic, according to...',    
    link: '/trends/blockchain-and-cryptocurrencys',
    thumbnail: '/img/blockchain-thumb.png'
    },
    {
    title: '2022 UX Design Trends',
    description: 'Emerging tech trends are the backbone of hundreds of industries. They can snap the spine of a company like a toothpick or rehab a dying business into a flourishing...',   
    link: '/trends/2022-ux-design-trends',
    thumbnail: '/img/ux-design-trends-thumb.png'
    },
    {
    title: 'From Facebook to Meta',
    description: 'It is now known as Meta and has a new, simpler logo to further reflect its commitment to the new brand and the metaverse. The term “metaverse” is the newest...',
    link: '/trends/facebook-to-meta',
    thumbnail: '/img/metaverse4-thumb.png'
    },
]


 const spotlightActivity = ({thumbnail, title, description, link}) => {

    return (
        <div className='spotlightActivity-wrapper'>
            {spotlightActivityData && spotlightActivityData.map(item => <div className='spotlightActivity-item'> 
            <div className='thumbnail'><img src={item.thumbnail} />
            </div>
            <div className='row-items'>
                <div className='title'>{item.title}</div>
                <div className='description'>{item.description}</div>
            </div>
            <a className='btn-default read-more-btn' href={item.link} ><>READ MORE</></a>
            </div> )}
        </div>
    )
}

export default spotlightActivity
